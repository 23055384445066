// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD_hlpxM7Kf6ajzYdfdbA0IY9Aps1V4WzA",
  authDomain: "your-domain.com",
  projectId: "crypto-fa9d8",
  storageBucket: "crypto-fa9d8.appspot.com",
  messagingSenderId: "903538190078",
  appId: "1:903538190078:web:bfe0cd779d6de3a9d2a7af"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };

export default app; // تأكد من تصدير التطبيق 