// وظيفة لتشفير البيانات الحساسة قبل تخزينها
export const encryptData = (data) => {
  // استخدام خوارزمية تشفير قوية
  return btoa(JSON.stringify(data));
};

// وظيفة لفك تشفير البيانات
export const decryptData = (encryptedData) => {
  try {
    return JSON.parse(atob(encryptedData));
  } catch {
    return null;
  }
};

// إضافة CSRF token
export const addCSRFToken = () => {
  const token = Math.random().toString(36).substring(7);
  localStorage.setItem('csrf_token', token);
  return token;
};

// التحقق من CSRF token
export const validateCSRFToken = (token) => {
  return token === localStorage.getItem('csrf_token');
}; 