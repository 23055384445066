import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import './Navbar.css';

const Navbar = ({ user, onLogin, onLogout }) => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();

  const toggleMobileMenu = () => {
    setMobileMenuActive(!mobileMenuActive);
    if (!mobileMenuActive) {
      setUserMenuActive(false); // إغلاق القائمة المنسدلة عند فتح القائمة الجانبية
    }
  };

  const closeMobileMenu = () => {
    setMobileMenuActive(false);
  };

  const toggleUserMenu = () => {
    setUserMenuActive(!userMenuActive);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && mobileMenuActive) {
        setMobileMenuActive(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mobileMenuActive]);

  const dropdownVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 'auto', transition: { duration: 0.3, ease: "easeOut" } },
    exit: { opacity: 0, height: 0, transition: { duration: 0.2 } }
  };

  return (
    <motion.header
      className="app-header"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <h1 className="app-title">
        <Link to="/">Crypto X</Link>
      </h1>

      <button
        className="mobile-menu-toggle"
        onClick={toggleMobileMenu}
        aria-label="القائمة"
      >
        <motion.div
          animate={{ rotate: mobileMenuActive ? 90 : 0 }}
          transition={{ duration: 0.3 }}
        >
          ☰
        </motion.div>
      </button>

      <AnimatePresence>
        {mobileMenuActive && (
          <motion.div
            className="mobile-menu-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={closeMobileMenu}
          />
        )}
      </AnimatePresence>

      <nav className={mobileMenuActive ? 'active' : ''}>
        <ul>
          <motion.li whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link to="/" onClick={closeMobileMenu}>الصفحة الرئيسية</Link>
          </motion.li>
          <motion.li whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link to="/reviews" onClick={closeMobileMenu}>مراجعة العملات</Link>
          </motion.li>
          <motion.li whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link to="/companies" onClick={closeMobileMenu}>مراجعة الشركات</Link>
          </motion.li>
          {user?.isAuthorized && (
            <motion.li whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Link to="/add-review" onClick={closeMobileMenu}>إضافة مراجعة</Link>
            </motion.li>
          )}
          <li className={`user-menu ${userMenuActive ? 'active' : ''}`}>
            <motion.button
              className="user-button"
              onClick={toggleUserMenu}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {user
                ? user.isAuthorized
                  ? 'Admin'
                  : 'Guest'
                : 'تسجيل الدخول'}
              <motion.span
                className="arrow"
                animate={{ rotate: userMenuActive ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                ▼
              </motion.span>
            </motion.button>
            <AnimatePresence>
              {userMenuActive && (
                <motion.div
                  className="dropdown-menu"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  {user ? (
                    <button onClick={() => { onLogout(); toggleUserMenu(); closeMobileMenu(); }}>
                      تسجيل الخروج
                    </button>
                  ) : (
                    <button onClick={() => { onLogin(); toggleUserMenu(); closeMobileMenu(); }}>
                      تسجيل الدخول
                    </button>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </li>
          <motion.li whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <button
              className="theme-toggle"
              onClick={toggleTheme}
              aria-label={isDarkMode ? 'التحول إلى الوضع النهاري' : 'التحول إلى الوضع الليلي'}
            >
              {isDarkMode ? '☀️' : '🌙'}
            </button>
          </motion.li>
        </ul>
      </nav>
    </motion.header>
  );
};

export default Navbar;