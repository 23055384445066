import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import HomePage from './components/Home/HomePage';
import ReviewsList from './components/Reviews/ReviewsList';
import CryptoReview from './components/Reviews/CryptoReview';
import LoginModal from './components/Auth/LoginModal';
import AdminPopup from './components/Auth/AdminPopup';
import CompaniesPage from './components/Companies/CompaniesPage';
import CompanyDetail from './components/Companies/CompanyDetail';
import CryptoDetail from './components/Reviews/CryptoDetail';
import Navbar from './components/Navigation/Navbar';
import { ThemeProvider } from './context/ThemeContext';
import './App.css';

const App = () => {
  const [user, setUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    const savedUser = localStorage.getItem('cryptoUser');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        const userData = {
          email: firebaseUser.email,
          uid: firebaseUser.uid,
          isAuthorized: true
        };
        setUser(userData);
        localStorage.setItem('cryptoUser', JSON.stringify(userData));
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      setUser(null);
      localStorage.removeItem('cryptoUser');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <ThemeProvider>
      <Router>
        <div className="app-container">
          <Navbar user={user} onLogout={handleLogout} onLogin={() => setShowLoginModal(true)} />
          <main className="main-content">
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/reviews" component={ReviewsList} />
              <Route path="/add-review">
                {user?.isAuthorized ? (
                  <CryptoReview />
                ) : (
                  <div className="unauthorized-message">
                    <h2>غير مصرح بالوصول</h2>
                    <p>عذراً، يجب تسجيل الدخول كمسؤول للوصول إلى هذه الصفحة</p>
                    <button onClick={() => setShowLoginModal(true)}>تسجيل الدخول كمسؤول</button>
                  </div>
                )}
              </Route>
              <Route path="/companies" component={CompaniesPage} />
              <Route path="/company/:id" component={CompanyDetail} />
              <Route path="/review/:id" component={CryptoDetail} />
            </Switch>
          </main>

          {showPopup && <AdminPopup onClose={() => setShowPopup(false)} onLogin={() => setUser(true)} />}
          {showLoginModal && (
            <LoginModal 
              onClose={() => setShowLoginModal(false)}
              onLogin={(userData) => {
                setUser(userData);
                setShowLoginModal(false);
                localStorage.setItem('cryptoUser', JSON.stringify(userData));
              }}
            />
          )}
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
 