import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from '../../FirebaseConfig';
import './CryptoReview.css';

const CryptoReview = () => {
    const [reviewType, setReviewType] = useState('crypto'); // crypto or company
    const [reviews, setReviews] = useState({ crypto: [], company: [] });
    const [editingReview, setEditingReview] = useState(null);
    const [review, setReview] = useState({
        name: '',
        symbol: '',
        rating: 5,
        pros: [''],
        cons: [''],
        content: '',
        description: '',
        date: new Date(),
        imageUrl: '',
        // حقول إضافية للشركات
        minDeposit: '',
        tradingPlatforms: [''],
        regulations: [''],
        paymentMethods: [''],
        customerSupport: '',
        tradingFeatures: [''],
        shariahStatus: 'halal', // القيمة الافتراضية: حلال
        shariahReason: '', // سبب الحكم الشرعي
    });

    const [loading, setLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    // جلب المراجعات الحالية
    useEffect(() => {
        const fetchReviews = async () => {
            try {
                // جلب مراجعات العملات
                const cryptoSnapshot = await getDocs(collection(db, "cryptoReviews"));
                const cryptoReviews = [];
                cryptoSnapshot.forEach((doc) => {
                    cryptoReviews.push({ id: doc.id, ...doc.data() });
                });

                // جلب مراجعات الشركات
                const companySnapshot = await getDocs(collection(db, "companyReviews"));
                const companyReviews = [];
                companySnapshot.forEach((doc) => {
                    companyReviews.push({ id: doc.id, ...doc.data() });
                });

                setReviews({
                    crypto: cryptoReviews,
                    company: companyReviews
                });
            } catch (error) {
                console.error("Error fetching reviews:", error);
            }
        };

        fetchReviews();
    }, [refreshKey]);

    const handleArrayChange = (field, index, value) => {
        const newArray = [...review[field]];
        newArray[index] = value;
        setReview({ ...review, [field]: newArray });
    };

    const addArrayField = (field) => {
        setReview({ ...review, [field]: [...review[field], ''] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const collectionName = reviewType === 'crypto' ? 'cryptoReviews' : 'companyReviews';
            
            // تحضير البيانات
            const reviewData = {
                ...review,
                date: new Date(),
                lastModified: new Date()
            };
            delete reviewData.id; // حذف الـ id إن وجد

            if (editingReview) {
                // تحديث مراجعة موجودة
                const docRef = doc(db, collectionName, editingReview);
                await updateDoc(docRef, reviewData);
                alert("تم تحديث المراجعة بنجاح!");
            } else {
                // إضافة مراجعة جديدة
                await addDoc(collection(db, collectionName), reviewData);
                alert("تمت إضافة المراجعة بنجاح!");
            }

            // إعادة تحميل البيانات
            const querySnapshot = await getDocs(collection(db, collectionName));
            const updatedReviews = [];
            querySnapshot.forEach((doc) => {
                updatedReviews.push({ id: doc.id, ...doc.data() });
            });

            setReviews(prev => ({
                ...prev,
                [reviewType]: updatedReviews
            }));

            // إعادة تعيين النموذج
            setEditingReview(null);
            setReview({
                name: '',
                symbol: '',
                rating: 5,
                pros: [''],
                cons: [''],
                content: '',
                description: '',
                date: new Date(),
                imageUrl: '',
                minDeposit: '',
                tradingPlatforms: [''],
                regulations: [''],
                paymentMethods: [''],
                customerSupport: '',
                tradingFeatures: [''],
                shariahStatus: 'halal',
                shariahReason: '',
            });

        } catch (error) {
            console.error("Error saving review:", error);
            alert("حدث خطأ أثناء حفظ المراجعة: " + error.message);
        } finally {
            setLoading(false);
            setRefreshKey(prev => prev + 1);
        }
    };

    // حذف مراجعة
    const handleDelete = async (id, type) => {
        if (window.confirm('هل أنت متأكد من حذف هذه المراجعة؟')) {
            setLoading(true); // إضافة حالة التحميل
            try {
                const collectionName = type === 'crypto' ? 'cryptoReviews' : 'companyReviews';
                const docRef = doc(db, collectionName, id);
                
                // التحقق من وجود المستند قبل الحذف
                const docSnap = await getDoc(docRef);
                if (!docSnap.exists()) {
                    throw new Error('المراجعة غير موجودة');
                }

                await deleteDoc(docRef);
                
                // تحديث القائمة المحلية
                setReviews(prev => ({
                    ...prev,
                    [type]: prev[type].filter(review => review.id !== id)
                }));
                
                alert('تم حذف المراجعة بنجاح');
            } catch (error) {
                console.error("Error deleting document:", error);
                alert('حدث خطأ أثناء الحذف: ' + error.message);
            } finally {
                setLoading(false);
                setRefreshKey(prev => prev + 1);
            }
        }
    };

    // تحميل مراجعة للتعديل
    const handleEdit = async (reviewToEdit) => {
        console.log("Editing review:", reviewToEdit);
        const type = reviewToEdit.tradingPlatforms ? 'company' : 'crypto';
        
        // تحديث النوع أولاً وانتظار تحديث الواجهة
        await new Promise(resolve => {
            setReviewType(type);
            setTimeout(resolve, 0);
        });
        
        // نسخ جميع البيانات مع التأكد من وجود المصفوفات والتاريخ
        const editData = {
            ...reviewToEdit,
            pros: reviewToEdit.pros || [''],
            cons: reviewToEdit.cons || [''],
            tradingPlatforms: reviewToEdit.tradingPlatforms || [''],
            regulations: reviewToEdit.regulations || [''],
            paymentMethods: reviewToEdit.paymentMethods || [''],
            tradingFeatures: reviewToEdit.tradingFeatures || [''],
            date: reviewToEdit.date || new Date()
        };
        
        // حذف حقل id من البيانات التي سيتم تحديثها
        delete editData.id;
        
        setEditingReview(reviewToEdit.id);
        setReview(editData);
        
        // التمرير إلى أعلى الصفحة بشكل سلس
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="crypto-review-container">
            <h2>{editingReview ? 'تعديل المراجعة' : 'إضافة مراجعة جديدة'}</h2>
            <div className="review-type-selector">
                <button 
                    className={`type-btn ${reviewType === 'crypto' ? 'active' : ''}`}
                    onClick={() => setReviewType('crypto')}
                >
                    مراجعة عملة
                </button>
                <button 
                    className={`type-btn ${reviewType === 'company' ? 'active' : ''}`}
                    onClick={() => setReviewType('company')}
                >
                    مراجعة شركة
                </button>
            </div>

            <form onSubmit={handleSubmit} className="review-form">
                <div className="form-group">
                    <label>الاسم:</label>
                    <input 
                        type="text" 
                        value={review.name}
                        onChange={(e) => setReview({...review, name: e.target.value})}
                        required
                    />
                </div>

                {reviewType === 'crypto' && (
                    <div className="form-group">
                        <label>رمز العملة:</label>
                        <input 
                            type="text" 
                            placeholder="مثال: BTC, ETH, BNB"
                            value={review.symbol} 
                            onChange={(e) => setReview({...review, symbol: e.target.value.toUpperCase()})}
                            required
                            maxLength="10"
                            className="symbol-input"
                        />
                        <small className="input-hint">أدخل الرمز المختصر للعملة المشفرة</small>
                    </div>
                )}

                {reviewType === 'company' && (
                    <>
                        <div className="form-group">
                            <label>الحد الأدنى للإيداع:</label>
                            <input 
                                type="text" 
                                value={review.minDeposit}
                                onChange={(e) => setReview({...review, minDeposit: e.target.value})}
                            />
                        </div>

                        <div className="form-group">
                            <label>منصات التداول:</label>
                            {review.tradingPlatforms.map((platform, index) => (
                                <div key={index} className="list-input">
                                    <input 
                                        type="text"
                                        value={platform}
                                        onChange={(e) => handleArrayChange('tradingPlatforms', index, e.target.value)}
                                    />
                                </div>
                            ))}
                            <button type="button" onClick={() => addArrayField('tradingPlatforms')} className="add-button">
                                + إضافة منصة
                            </button>
                        </div>

                        <div className="form-group">
                            <label>التراخيص والتنظيمات:</label>
                            {review.regulations.map((reg, index) => (
                                <div key={index} className="list-input">
                                    <input 
                                        type="text"
                                        value={reg}
                                        onChange={(e) => handleArrayChange('regulations', index, e.target.value)}
                                    />
                                </div>
                            ))}
                            <button type="button" onClick={() => addArrayField('regulations')} className="add-button">
                                + إضافة ترخيص
                            </button>
                        </div>

                        <div className="form-group">
                            <label>وسائل الدفع:</label>
                            {review.paymentMethods.map((method, index) => (
                                <div key={index} className="list-input">
                                    <input 
                                        type="text"
                                        value={method}
                                        onChange={(e) => handleArrayChange('paymentMethods', index, e.target.value)}
                                    />
                                </div>
                            ))}
                            <button type="button" onClick={() => addArrayField('paymentMethods')} className="add-button">
                                + إضافة وسيلة دفع
                            </button>
                        </div>

                        <div className="form-group">
                            <label>خدمة العملاء:</label>
                            <textarea 
                                value={review.customerSupport}
                                onChange={(e) => setReview({...review, customerSupport: e.target.value})}
                                rows="3"
                            />
                        </div>
                    </>
                )}

                {/* الحقول المشتركة */}
                <div className="form-group">
                    <label>رابط الصورة:</label>
                    <input 
                        type="url" 
                        value={review.imageUrl}
                        onChange={(e) => setReview({...review, imageUrl: e.target.value})}
                    />
                </div>

                <div className="form-group">
                    <label>التقييم:</label>
                    <select 
                        value={review.rating}
                        onChange={(e) => setReview({...review, rating: Number(e.target.value)})}
                    >
                        {[1,2,3,4,5].map(num => (
                            <option key={num} value={num}>{num}</option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label>وصف مختصر:</label>
                    <textarea 
                        value={review.description}
                        onChange={(e) => setReview({...review, description: e.target.value})}
                        rows="2"
                    />
                </div>

                <div className="form-group">
                    <label>المحتوى الكامل:</label>
                    <textarea 
                        value={review.content}
                        onChange={(e) => setReview({...review, content: e.target.value})}
                        rows="6"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>المميزات:</label>
                    {review.pros.map((pro, index) => (
                        <div key={index} className="list-input">
                            <input 
                                type="text"
                                value={pro}
                                onChange={(e) => handleArrayChange('pros', index, e.target.value)}
                            />
                        </div>
                    ))}
                    <button type="button" onClick={() => addArrayField('pros')} className="add-button">
                        + إضافة ميزة
                    </button>
                </div>

                <div className="form-group">
                    <label>العيوب:</label>
                    {review.cons.map((con, index) => (
                        <div key={index} className="list-input">
                            <input 
                                type="text"
                                value={con}
                                onChange={(e) => handleArrayChange('cons', index, e.target.value)}
                            />
                        </div>
                    ))}
                    <button type="button" onClick={() => addArrayField('cons')} className="add-button">
                        + إضافة عيب
                    </button>
                </div>

                <div className="form-group">
                    <label>الحكم الشرعي:</label>
                    <select 
                        value={review.shariahStatus}
                        onChange={(e) => setReview({...review, shariahStatus: e.target.value})}
                    >
                        <option value="halal">حلال</option>
                        <option value="haram">حرام</option>
                        <option value="suspicious">مشبوه</option>
                    </select>
                </div>

                <div className="form-group">
                    <label>سبب الحكم الشرعي:</label>
                    <textarea 
                        value={review.shariahReason}
                        onChange={(e) => setReview({...review, shariahReason: e.target.value})}
                        rows="3"
                        placeholder="اذكر سبب الحكم الشرعي بالتفصيل..."
                    />
                </div>

                <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? 'جاري الحفظ...' : (editingReview ? 'تحديث المراجعة' : 'نشر المراجعة')}
                </button>
            </form>

            {/* قسم عرض المراجعات الحالية */}
            <div className="current-reviews">
                <h3>المراجعات الحالية</h3>
                
                <div className="reviews-tabs">
                    <button 
                        className={`tab-btn ${reviewType === 'crypto' ? 'active' : ''}`}
                        onClick={() => {
                            setReviewType('crypto');
                            setEditingReview(null); // إعادة تعيين حالة التعديل عند تغيير النوع
                        }}
                    >
                        مراجعات العملات
                    </button>
                    <button 
                        className={`tab-btn ${reviewType === 'company' ? 'active' : ''}`}
                        onClick={() => {
                            setReviewType('company');
                            setEditingReview(null); // إعادة تعيين حالة التعديل عند تغيير النوع
                        }}
                    >
                        مراجعات الشركات
                    </button>
                </div>

                <div className="reviews-list">
                    {reviews[reviewType].map(review => (
                        <div key={review.id} className="review-item">
                            <div className="review-info">
                                <h4>{review.name}</h4>
                                <p>{review.description}</p>
                                <div className="review-meta">
                                    <span>التقييم: {review.rating}/5</span>
                                    <span>التاريخ: {new Date(review.date?.seconds * 1000).toLocaleDateString('ar-EG')}</span>
                                </div>
                            </div>
                            <div className="review-actions">
                                <button 
                                    className="edit-btn"
                                    onClick={() => handleEdit(review)}
                                    disabled={loading}
                                >
                                    {loading ? 'جاري التحميل...' : 'تعديل'}
                                </button>
                                <button 
                                    className="delete-btn"
                                    onClick={() => handleDelete(review.id, reviewType)}
                                    disabled={loading}
                                >
                                    {loading ? 'جاري الحذف...' : 'حذف'}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CryptoReview; 