import React, { useEffect, useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../FirebaseConfig';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './ReviewsList.css';

const ReviewsList = () => {
    const [reviews, setReviews] = useState([]);
    const [filteredReviews, setFilteredReviews] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "cryptoReviews"));
                const reviewsData = [];
                querySnapshot.forEach((doc) => {
                    reviewsData.push({ id: doc.id, ...doc.data() });
                });
                setReviews(reviewsData);
                setFilteredReviews(reviewsData);
            } catch (error) {
                console.error("Error fetching reviews:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchReviews();
    }, []);

    useEffect(() => {
        const filtered = reviews.filter(review => 
            review.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            review.symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
            review.description.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredReviews(filtered);
    }, [searchQuery, reviews]);

    if (loading) {
        return <div className="loading">جاري التحميل...</div>;
    }

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": filteredReviews.map((review, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "item": {
                "@type": "Product",
                "name": review.name,
                "description": review.description,
                "category": "Cryptocurrency",
                "image": review.imageUrl,
                "offers": {
                    "@type": "Offer",
                    "price": "0",
                    "priceCurrency": "USD"
                }
            }
        }))
    };

    return (
        <div className="reviews-container">
            <Helmet>
                <title>مراجعات العملات الرقمية - Crypto X</title>
                <meta name="description" content="اكتشف مراجعات شاملة للعملات الرقمية، تحليلات الخبراء، ورؤى فورية. اتخذ قرارات مستنيرة مع مراجعاتنا التفصيلية للعملات المشفرة." />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <h1 className="reviews-title">مراجعات العملات الرقمية</h1>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="ابحث عن عملة..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                />
            </div>
            <div className="reviews-grid">
                {filteredReviews.map(review => (
                    <div key={review.id} className="review-card">
                        <div className="review-header">
                            {review.imageUrl && (
                                <img 
                                    src={review.imageUrl} 
                                    alt={review.name} 
                                    className="crypto-logo"
                                />
                            )}
                            <div className="review-title">
                                <h2>{review.name}</h2>
                                <span className="symbol">{review.symbol}</span>
                            </div>
                        </div>
                        <div className="rating">
                            <div className="stars">
                                {[...Array(5)].map((_, index) => (
                                    <span 
                                        key={index} 
                                        className={index < review.rating ? 'star filled' : 'star'}
                                    >
                                        ★
                                    </span>
                                ))}
                            </div>
                            <span className="rating-value">{review.rating}/5</span>
                        </div>
                        <div className="review-body">
                            <p className="description">{review.description}</p>
                            <div className="features-section">
                                <div className="pros">
                                    <h3>المميزات</h3>
                                    <ul>
                                        {review.pros?.slice(0, 3).map((pro, index) => (
                                            <li key={index}>{pro}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="cons">
                                    <h3>العيوب</h3>
                                    <ul>
                                        {review.cons?.slice(0, 3).map((con, index) => (
                                            <li key={index}>{con}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <button 
                                className="read-more" 
                                onClick={() => history.push(`/review/${review.id}`)} // غيرنا من /reviews إلى /review
                            >
                                اقرأ المزيد
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ReviewsList;