import React from 'react';
import { Helmet } from 'react-helmet';

const ReviewStructuredData = ({ review }) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": review.name,
        "description": review.description,
        "image": review.imageUrl,
        "category": "Cryptocurrency",
        "review": {
            "@type": "Review",
            "reviewRating": {
                "@type": "Rating",
                "ratingValue": review.rating,
                "bestRating": "5",
                "worstRating": "1"
            },
            "author": {
                "@type": "Person",
                "name": review.author || "Crypto X Expert"
            },
            "datePublished": review.date || new Date().toISOString(),
            "reviewBody": review.description
        },
        "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
        }
    };

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
            </script>
        </Helmet>
    );
};

export default ReviewStructuredData; 