import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import './LoginModal.css';
import { encryptData, addCSRFToken } from '../../utils/security';

const LoginModal = ({ onClose, onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/invalid-credential':
        return 'البريد الإلكتروني أو كلمة المرور غير صحيحة';
      case 'auth/user-not-found':
        return 'لم يتم العثور على حساب بهذا البريد الإلكتروني';
      case 'auth/wrong-password':
        return 'كلمة المرور غير صحيحة';
      case 'auth/invalid-email':
        return 'البريد الإلكتروني غير صالح';
      case 'auth/user-disabled':
        return 'تم تعطيل هذا الحساب';
      case 'auth/too-many-requests':
        return 'تم تجاوز عدد محاولات تسجيل الدخول. يرجى المحاولة لاحقاً';
      case 'auth/network-request-failed':
        return 'خطأ في الاتصال بالخادم. تأكد من اتصالك بالإنترنت';
      default:
        return 'حدث خطأ في تسجيل الدخول. يرجى المحاولة مرة أخرى';
    }
  };

  const createNewUser = async () => {
    try {
      const auth = getAuth();
      const result = await createUserWithEmailAndPassword(auth, 'ham7code03@gmail.com', '123456');
      console.log('User created successfully:', result);
      return result;
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        console.log('User already exists, proceeding with login');
        return null;
      }
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    // إضافة CSRF token
    const csrfToken = addCSRFToken();

    try {
      const auth = getAuth();
      const result = await signInWithEmailAndPassword(auth, email, password);
      
      // تشفير بيانات المستخدم قبل تخزينها
      const userData = encryptData({
        email: result.user.email,
        uid: result.user.uid,
        isAuthorized: true,
        csrfToken
      });

      // تخزين البيانات المشفرة
      localStorage.setItem('cryptoUser', userData);
      
      onLogin(JSON.parse(atob(userData)));
    } catch (error) {
      // تسجيل محاولات تسجيل الدخول الفاشلة
      console.error('Failed login attempt:', {
        timestamp: new Date().toISOString(),
        ip: 'client-side',
        error: error.code
      });

      const unauthorizedUser = {
        email: 'guest',
        uid: 'guest',
        isAuthorized: false
      };
      onLogin(unauthorizedUser);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay" onClick={(e) => {
      if (e.target.className === 'modal-overlay') onClose();
    }}>
      <div className="login-modal">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>تسجيل الدخول</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>البريد الإلكتروني:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="أدخل البريد الإلكتروني"
            />
          </div>
          <div className="form-group">
            <label>كلمة المرور:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="أدخل كلمة المرور"
            />
          </div>
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? 'جاري التسجيل...' : 'تسجيل الدخول'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginModal; 