import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from '../../FirebaseConfig';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion'; // استيراد framer-motion
import './HomePage.css';

const HomePage = () => {
  const [latestReviews, setLatestReviews] = useState({
    crypto: [],
    companies: []
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLatestReviews = async () => {
      try {
        // جلب أحدث مراجعات العملات
        const cryptoQuery = query(
          collection(db, "cryptoReviews"),
          orderBy("date", "desc"),
          limit(3)
        );
        const cryptoSnapshot = await getDocs(cryptoQuery);
        const cryptoReviews = [];
        cryptoSnapshot.forEach((doc) => {
          cryptoReviews.push({ id: doc.id, ...doc.data() });
        });

        // جلب أحدث مراجعات الشركات
        const companiesQuery = query(
          collection(db, "companyReviews"),
          orderBy("date", "desc"),
          limit(3)
        );
        const companiesSnapshot = await getDocs(companiesQuery);
        const companyReviews = [];
        companiesSnapshot.forEach((doc) => {
          companyReviews.push({ id: doc.id, ...doc.data() });
        });

        setLatestReviews({
          crypto: cryptoReviews,
          companies: companyReviews
        });
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLatestReviews();
  }, []);

  // إعدادات الأنيميشن
  const heroVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, duration: 0.5, ease: "easeOut" }
    })
  };

  const loaderVariants = {
    animate: {
      rotate: 360,
      transition: { repeat: Infinity, duration: 1, ease: "linear" }
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <motion.div
          className="loader"
          variants={loaderVariants}
          animate="animate"
        />
        <p>جاري التحميل...</p>
      </div>
    );
  }

  return (
    <>
      {/* قسم الترحيب مع أنيميشن */}
      <motion.section
        className="hero-section"
        initial="hidden"
        animate="visible"
        variants={heroVariants}
      >
        <h1>مرحباً بك في موقعنا لمراجعات العملات الرقمية وشركات التداول</h1>
        <p className="hero-description">
          نقدم لك مراجعات موثوقة وتحليلات دقيقة للعملات الرقمية وشركات التداول.
          اكتشف أفضل الفرص الاستثمارية واتخذ قرارات مدروسة.
        </p>
      </motion.section>

      {/* قسم أحدث مراجعات العملات */}
      <section className="reviews-section">
        <div className="section-header">
          <h2>أحدث مراجعات العملات الرقمية</h2>
          <p>تعرف على آخر التحليلات والمراجعات للعملات الرقمية الرائدة في السوق</p>
          <Link to="/reviews" className="view-all">عرض جميع المراجعات</Link>
        </div>
        <div className="reviews-grid">
          <AnimatePresence>
            {latestReviews.crypto.map((review, index) => (
              <motion.div
                key={review.id}
                className="review-card"
                custom={index}
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                exit={{ opacity: 0, y: -20 }}
              >
                <div className="card-header">
                  {review.imageUrl && (
                    <img src={review.imageUrl} alt={review.name} className="review-image" />
                  )}
                  <h3>{review.name}</h3>
                  <span className="symbol">{review.symbol}</span>
                </div>
                <div className="rating">
                  {[...Array(5)].map((_, index) => (
                    <span key={index} className={index < review.rating ? 'star filled' : 'star'}>★</span>
                  ))}
                </div>
                <p className="description">{review.description}</p>
                <Link to={`/review/${review.id}`} className="read-more">قراءة المزيد</Link>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </section>

      {/* قسم أحدث مراجعات الشركات */}
      <section className="reviews-section">
        <div className="section-header">
          <h2>أفضل شركات التداول الموثوقة</h2>
          <p>اكتشف أفضل شركات التداول المرخصة والموثوقة في المنطقة العربية</p>
          <Link to="/companies" className="view-all">عرض جميع الشركات</Link>
        </div>
        <div className="reviews-grid">
          <AnimatePresence>
            {latestReviews.companies.map((company, index) => (
              <motion.div
                key={company.id}
                className="review-card company-card"
                custom={index}
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                exit={{ opacity: 0, y: -20 }}
              >
                <div className="card-header">
                  {company.imageUrl && (
                    <img src={company.imageUrl} alt={company.name} className="review-image" />
                  )}
                  <h3>{company.name}</h3>
                </div>
                <div className="rating">
                  {[...Array(5)].map((_, index) => (
                    <span key={index} className={index < company.rating ? 'star filled' : 'star'}>★</span>
                  ))}
                </div>
                <div className="company-features">
                  {company.minDeposit && (
                    <div className="feature">
                      <span className="feature-label">الحد الأدنى للإيداع:</span>
                      <span className="feature-value">{company.minDeposit}</span>
                    </div>
                  )}
                  {company.tradingPlatforms?.[0] && (
                    <div className="feature">
                      <span className="feature-label">منصة التداول:</span>
                      <span className="feature-value">{company.tradingPlatforms[0]}</span>
                    </div>
                  )}
                </div>
                <Link to={`/company/${company.id}`} className="read-more">عرض التفاصيل</Link>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </section>
    </>
  );
};

export default HomePage;