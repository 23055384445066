import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import './AdminPopup.css';

const AdminPopup = ({ onClose, onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const createAdminData = async () => {
            const db = getFirestore();
            const adminRef = doc(db, "admins", "adminData");
            const docSnap = await getDoc(adminRef);

            if (!docSnap.exists()) {
                await setDoc(adminRef, {
                    email: "ham7code03@gmail.com",
                    password: "123456"
                });
                console.log("تم إنشاء بيانات الأدمن");
            }
        };

        createAdminData();
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        const db = getFirestore();
        const adminRef = doc(db, "admins", "adminData");
        const docSnap = await getDoc(adminRef);

        if (docSnap.exists()) {
            const adminData = docSnap.data();
            console.log("Admin Data:", adminData);
            console.log("Entered Email:", email);
            console.log("Entered Password:", password);
            if (email === adminData.email && password === adminData.password) {
                onLogin();
                onClose();
            } else {
                setError("البريد الإلكتروني أو كلمة المرور غير صحيحة");
            }
        } else {
            setError("لا توجد بيانات أدمن");
        }
    };

    return (
        <div className="popup-container">
            <div className="popup">
                <h2>تسجيل دخول الأدمن</h2>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleLogin}>
                    <div>
                        <label>البريد الإلكتروني:</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <div>
                        <label>كلمة المرور:</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <button type="submit">تسجيل الدخول</button>
                </form>
                <button onClick={onClose}>إغلاق</button>
            </div>
        </div>
    );
};

export default AdminPopup; 