import React, { useEffect, useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../FirebaseConfig';
import './CompaniesPage.css';
import { useHistory } from 'react-router-dom';

const CompaniesPage = () => {
    const [companies, setCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "companyReviews"));
                const companiesData = [];
                querySnapshot.forEach((doc) => {
                    companiesData.push({ id: doc.id, ...doc.data() });
                });
                setCompanies(companiesData);
                setFilteredCompanies(companiesData);
            } catch (error) {
                console.error("Error fetching companies:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCompanies();
    }, []);

    useEffect(() => {
        const filtered = companies.filter(company => 
            company.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            company.description.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredCompanies(filtered);
    }, [searchQuery, companies]);

    if (loading) {
        return <div className="loading">جاري التحميل...</div>;
    }

    return (
        <>
            <h1 className="section-title">
                <span className="icon">🛡️</span>
                أفضل المنصات الموثوقة
            </h1>
            
            <div className="search-container">
                <input
                    type="text"
                    placeholder="ابحث عن شركة..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                />
            </div>
            
            <div className="companies-grid">
                {filteredCompanies.map(company => (
                    <div key={company.id} className="review-card company-card">
                        <div className="card-header">
                            {company.imageUrl && (
                                <img 
                                    src={company.imageUrl} 
                                    alt={company.name} 
                                    className="company-logo"
                                />
                            )}
                            <h3>{company.name}</h3>
                            <div className="rating">
                                <span className="rating-value">{company.rating}</span>
                                <div className="stars">
                                    {[...Array(5)].map((_, index) => (
                                        <span 
                                            key={index} 
                                            className={index < company.rating ? 'star filled' : 'star'}
                                        >
                                            ★
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="company-features">
                            {company.tradingPlatforms?.[0] && (
                                <div className="feature">
                                    <span className="check">✓</span>
                                    {company.tradingPlatforms[0]}
                                </div>
                            )}
                            {company.regulations?.[0] && (
                                <div className="feature">
                                    <span className="check">✓</span>
                                    {company.regulations[0]}
                                </div>
                            )}
                            {company.paymentMethods?.[0] && (
                                <div className="feature">
                                    <span className="check">✓</span>
                                    {company.paymentMethods[0]}
                                </div>
                            )}
                        </div>

                        <button 
                            className="read-more" 
                            onClick={() => history.push(`/company/${company.id}`)}
                        >
                            قراءة المراجعة
                        </button>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CompaniesPage; 