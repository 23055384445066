import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../FirebaseConfig';
import './CompanyDetail.css';

const CompanyDetail = () => {
    const { id } = useParams();
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCompany = async () => {
            try {
                const docRef = doc(db, "companyReviews", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setCompany({ id: docSnap.id, ...docSnap.data() });
                }
            } catch (error) {
                console.error("Error fetching company:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCompany();
    }, [id]);

    if (loading) {
        return <div className="loading">جاري التحميل...</div>;
    }

    if (!company) {
        return <div className="error">لم يتم العثور على الشركة</div>;
    }

    return (
        <div className="company-detail-container">
            <div className="company-detail-header">
                {company.imageUrl && (
                    <img src={company.imageUrl} alt={company.name} className="company-detail-logo" />
                )}
                <h1>{company.name}</h1>
                <div className="rating-large">
                    <span className="rating-value">{company.rating}</span>
                    <div className="stars">
                        {[...Array(5)].map((_, index) => (
                            <span key={index} className={index < company.rating ? 'star filled' : 'star'}>
                                ★
                            </span>
                        ))}
                    </div>
                </div>
            </div>

            <div className="company-detail-content">
                <div className="info-section">
                    <h2>معلومات أساسية</h2>
                    <div className="info-grid">
                        <div className="info-item">
                            <h3>الحد الأدنى للإيداع</h3>
                            <p>{company.minDeposit}</p>
                        </div>
                        <div className="info-item">
                            <h3>خدمة العملاء</h3>
                            <p>{company.customerSupport}</p>
                        </div>
                    </div>
                </div>

                <div className="features-section">
                    <h2>منصات التداول</h2>
                    <div className="features-list">
                        {company.tradingPlatforms?.map((platform, index) => (
                            <div key={index} className="feature-item">
                                <span className="check">✓</span>
                                {platform}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="features-section">
                    <h2>التراخيص والتنظيمات</h2>
                    <div className="features-list">
                        {company.regulations?.map((reg, index) => (
                            <div key={index} className="feature-item">
                                <span className="check">✓</span>
                                {reg}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="features-section">
                    <h2>وسائل الدفع</h2>
                    <div className="features-list">
                        {company.paymentMethods?.map((method, index) => (
                            <div key={index} className="feature-item">
                                <span className="check">✓</span>
                                {method}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="description-section">
                    <h2>وصف الشركة</h2>
                    <p>{company.description}</p>
                </div>

                <div className="pros-cons-section">
                    <div className="pros">
                        <h2>المميزات</h2>
                        <ul>
                            {company.pros?.map((pro, index) => (
                                <li key={index}>{pro}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="cons">
                        <h2>العيوب</h2>
                        <ul>
                            {company.cons?.map((con, index) => (
                                <li key={index}>{con}</li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="content-section">
                    <h2>المراجعة الكاملة</h2>
                    <div className="full-content">
                        {company.content}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyDetail; 