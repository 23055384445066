import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../FirebaseConfig';
import ReviewStructuredData from './ReviewStructuredData';
import './CryptoDetail.css';

const CryptoDetail = () => {
    const { id } = useParams();
    const [crypto, setCrypto] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCrypto = async () => {
            try {
                const docRef = doc(db, "cryptoReviews", id);
                const docSnap = await getDoc(docRef);
                
                if (docSnap.exists()) {
                    setCrypto({ id: docSnap.id, ...docSnap.data() });
                } else {
                    throw new Error("لم يتم العثور على المراجعة");
                }
            } catch (error) {
                console.error("Error fetching crypto:", error);
                alert(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCrypto();
    }, [id]);

    if (loading) {
        return <div className="loading">جاري التحميل...</div>;
    }

    if (!crypto) {
        return <div className="error">لم يتم العثور على المراجعة</div>;
    }

    return (
        <div className="crypto-detail-container">
            <ReviewStructuredData review={crypto} />
            <div className="crypto-detail-header">
                {crypto.imageUrl && (
                    <img 
                        src={crypto.imageUrl} 
                        alt={crypto.name} 
                        className="crypto-detail-logo"
                    />
                )}
                <h1>{crypto.name}</h1>
                <span className="symbol">{crypto.symbol}</span>
                
                <div className="rating-large">
                    <div className="stars">
                        {[...Array(5)].map((_, index) => (
                            <span 
                                key={index} 
                                className={index < crypto.rating ? 'star filled' : 'star'}
                            >
                                ★
                            </span>
                        ))}
                    </div>
                    <span className="rating-value">{crypto.rating}/5</span>
                </div>
            </div>

            <div className="crypto-detail-content">
                <div className="description-section">
                    <h2>نبذة عن العملة</h2>
                    <p>{crypto.description}</p>
                </div>

                <div className="shariah-section">
                    <h2>الحكم الشرعي</h2>
                    <div className={`shariah-status status-${crypto.shariahStatus}`}>
                        {crypto.shariahStatus === 'halal' && 'حلال'}
                        {crypto.shariahStatus === 'haram' && 'حرام'}
                        {crypto.shariahStatus === 'suspicious' && 'مشبوه'}
                    </div>
                    {crypto.shariahReason && (
                        <div className="shariah-reason">
                            <h3>السبب:</h3>
                            <p>{crypto.shariahReason}</p>
                        </div>
                    )}
                </div>

                <div className="pros-cons-section">
                    <div className="pros">
                        <h2>المميزات</h2>
                        <ul>
                            {crypto.pros?.map((pro, index) => (
                                <li key={index}>{pro}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="cons">
                        <h2>العيوب</h2>
                        <ul>
                            {crypto.cons?.map((con, index) => (
                                <li key={index}>{con}</li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="content-section">
                    <h2>المراجعة الكاملة</h2>
                    <div className="full-content">
                        {crypto.content}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CryptoDetail; 